import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ReactNotifications, { store } from 'react-notifications-component';

import common from '../../common';
import actions from '../../redux/actions';
import Logo from '../../public/icons/favicon.svg';
import LogoSchoolOnWeb from '../../public/icons/schoolsonweb-logo-only.png';
// import Play from '../../public/icons/play.svg';
/*import Noesis from '../../public/images/noesis.svg';
import PlayStore from '../../public/images/play-store.svg';
import AppStore from '../../public/images/app-store.svg';
import Element from '../../public/images/element1.svg';
import Home1 from '../../public/images/home1.svg';
import Home2 from '../../public/images/home2.svg';
import Home3 from '../../public/images/home3.svg';
import Home4 from '../../public/images/home4.svg';
import Dots from '../../public/icons/dots.png';*/
import 'react-notifications-component/dist/theme.css';
import config from '../../config';
import './styles.css';
const themePrimaryColor = config.themePrimaryColor;
const themeSecondaryColor = config.themeSecondaryColor;
const themePrimaryLightColor = config.themePrimaryLightColor;
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brand_name: '',
      name: '',
      email: '',
      mobile: '',
      center_type: 1,
      terms: false,
      isChecked: true,
      logo_url:require("../../public/images/"+config.brandLogo)
    }
  }
  componentDidMount(){
    console.log(window.location.host);
    if(window.location.host){
      this.fetchLoginPageSetting(window.location.host);
      }
      console.log("this.state.logo_url",this.state.logo_url); 
    }

  
  
  fetchLoginPageSetting = async (path) =>{
    console.log("path");
    console.log(path);
    const url = `${common.getCustomLoginPageSettings}`;
    const data = new FormData();
    data.append('path', "http://"+path);
    fetch(common.apiBase+url, {
      method: "POST",
      body: data
    }).then(res => res.json())
    .then(
        (result) => {
        this.setState({saveSettingsData: result.data});
        localStorage.setItem('loginPageSettings', JSON.stringify(result.data));
        this.setAllSettingsInLocalStorage();
        }
    )
  }

  setAllSettingsInLocalStorage = () =>{
    let getData = localStorage.getItem('loginPageSettings');
    console.log("getData");
    console.log(getData);

    let filteredLogoURL = this.state.saveSettingsData?.filter(data => {
      return data.key === 'logo_url';
    });  

    let filteredPrimaryColor = this.state.saveSettingsData?.filter(data => {
      return data.key === 'primary_color';
    });  

    let filteredSecondaryColor = this.state.saveSettingsData?.filter(data => {
      return data.key === 'secondary_color';
      
    }); 

    let filteredPrimaryLightColor = this.state.saveSettingsData.filter(data => {
      return data.key === 'primary_color_light';
      
    }); 

    // let filteredLoginBanner = this.state.saveSettingsData.filter(data => {
    //   return data.key === 'login_banner';
      
    // }); 
    // if(filteredLoginBanner){
    //   let setLoginBanner = filteredLoginBanner.map((n) => n.value);
    //   localStorage.setItem('login_banner', JSON.stringify(setLoginBanner[0]));
    // }

    if(filteredPrimaryLightColor){
      let setPrimaryLightColor = filteredPrimaryLightColor.map((n) => n.value);
      localStorage.setItem('primary_color_light', setPrimaryLightColor[0]);
    }

    if(filteredSecondaryColor){
      let setSecondaryColor = filteredSecondaryColor.map((n) => n.value);
      localStorage.setItem('secondary_color', setSecondaryColor[0]);
    }

    if(filteredPrimaryColor){
      let setPrimaryColor = filteredPrimaryColor.map((n) => n.value);
      localStorage.setItem('primary_color', setPrimaryColor[0]);
      
    }

    if(filteredLogoURL){
      let setLogoURL = filteredLogoURL.map((n) => n.value);
      localStorage.setItem('logo_url', setLogoURL[0]);
    }

    window.addEventListener('storage', () => {
      // When local storage changes do something like a refresh
      window.location.reload(true)
     
    });




    document.documentElement.style.setProperty('--primary', localStorage.getItem('primary_color') != 'undefined' ?  localStorage.getItem('primary_color'): themePrimaryColor);
    document.documentElement.style.setProperty('--secondary',localStorage.getItem('secondary_color')!= 'undefined' ? localStorage.getItem('secondary_color') : themeSecondaryColor);
    document.documentElement.style.setProperty('--primary-rgba',localStorage.getItem('primary_color_light')!= 'undefined' ?  localStorage.getItem('primary_color_light'): themePrimaryLightColor);
  

  }

  centerChange = (e) => {
    this.setState({ center_type: e.target.value })
  } 

  brandChange = (e) => {
    this.setState({ brand_name: e.target.value })
  }

  nameChange = (e) => {
    this.setState({ name: e.target.value })
  }

  emailChange = (e) => {
    this.setState({ email: e.target.value })
  }

  mobileChange = (e) => {
    const mobile = (e.target.validity.valid) ? e.target.value : this.state.mobile;
    this.setState({ mobile })
  }

  termChange = (e) => {
    this.setState({ terms: !this.state.terms })
  }

  onSubmit = async () => {
    const { name, email, mobile, brand_name, center_type } = this.state
    if(common.validateEmail(email)) {
      await this.props.centerCreate(name, email, mobile, brand_name, center_type);
    }
    else {
      store.addNotification({
        title: 'Invalid email',
        message: 'Please enter a valid email',
        type: 'danger',
        container: 'top-right',
        dismiss: {
          duration: 3000
        }
      })
    }
  }

  render() {
    const { brand_name, name, email, mobile } = this.state;
    const enabled = this.state.brand_name.length > 0 && this.state.name.length > 0
                    && this.state.email.length > 0 && this.state.mobile.length === 10
                    && this.state.terms
    return (
      <div className="main">
        <ReactNotifications/>
        <div className="header">
          <div className="logo">
            {/* <img src={require("../../public/images/"+config.brandLogo)} alt="Brand logo"/> */}
            {/* {"localStorage.getItem('logo_url')", localStorage.getItem('logo_url')} */}
            {!this.state.logo_url && localStorage.getItem('logo_url') && localStorage.getItem('logo_url') != 'undefined' && 
              <span><img src={localStorage.getItem('logo_url')} alt="Brand logo"/></span>
            }
            {this.state.logo_url && 
            <span><img src={this.state.logo_url} alt="Brand logo"/></span>
            }
          </div>
          <div className="header-middle">
            <div>
              About
            </div>
            <div>
              Curriculum
            </div>
            <div>
              Test Prep
            </div>
          </div>
          <div className="signup">
            <Link to={'/login'}>
              <button>
                Log in
              </button>
            </Link>
          </div>
        </div>
        <div className="form-div">

        </div>
        {/* <div className="form-div">
          <div className="slider">
            For Schools
            <span>Non-stop learning platform for all your needs.<br/>Run your own
              web application as soon as you sign up.<br/>Manage learning, Tests and schedule.<br/>
              Just a click away to bring faculties onboard and<br/>engage students.</span>
          </div>
          <div className="main-form">
            <div className="form-outer">
              <span className="setup">
                Setup your Organisation in a minute
              </span>
              <select value={this.state.center_type} onChange={this.centerChange}>
                <option value={1}>For School</option>
                <option value={2}>For Institutes</option>
              </select>
              <div className="form">
                <div className="input-field">
                  {this.state.brand_name.length === 0 ? <div></div> : <span>Brand Name</span>}
                  <input placeholder='Brand Name' name='brand' type='text'
                   value={brand_name} onChange={this.brandChange}/>
                </div> 
                <div className="input-field">
                  {this.state.name.length === 0 ? <div></div> : <span>Name</span>}
                  <input placeholder='Name' name='name' type='text'
                   value={name} onChange={this.nameChange}/>
                </div> 
                <div className="input-field">
                  {this.state.email.length === 0 ? <div></div> : <span>Email</span>}
                  <input placeholder='Email' name='email' type="email"
                   value={email} onChange={this.emailChange}/>
                </div> 
                <div className="input-field">
                  {this.state.mobile.length === 0 ? <div></div> : <span>Mobile No.</span>}
                  <input placeholder='Mobile No.' name='mobile' type='text' maxLength={10}
                   value={mobile} onInput={this.mobileChange} pattern="[0-9]*"/>
                </div>
                <div className="terms">
                  <input type="checkbox" checked={this.state.term} onChange={this.termChange}/>
                  <span>I agree to the <Link to="/terms">Terms & Conditions</Link></span>
                </div> 
              </div>
              <button disabled={!enabled} onClick={this.onSubmit}>
                Signup for free
              </button>
            </div>
          </div>
        </div>
        <div className="slide-1">
          <div className="info">
            <span>Students & Faculties<br/>Administration</span>
            <img src={Home1} alt="students"/>
          </div>
          <div className="cards">
            <div className="info-card">
              <img src={Element} alt="ele"/>
              <span>Branches Management</span>
              <div className="info-points">
                Create Institute branches and respective branch heads
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Create Students & Faculties
                </div>
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Manage Classes
                </div>
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Manage & Plan Exams
                </div>
              </div>
            </div>
            <div className="info-card">
              <img src={Element} alt="ele"/>
              <span>Faculties & Students Creation</span>
              <div className="info-points">
                Create profiles and invite on application
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Assign Class & Section
                </div>
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Assign Study Material
                </div>
                <div className="info-point">
                  <div id="info-bullets"></div>
                  Assign Sessions & Tests
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="slide-2">
          <div className="info2">
            <img src={Element} alt="ele"/>
            <div>
              <span>Go Live</span>
              <span>Online Live Class Solution</span>
            </div>
            <p>We build results-oriented brand strategies and continually refine your campaigns
            for the greatest outcome. From full-scale digital marketing and advertising
            strategy, right through to our precise execution and reporting...that's right, 
            we've.</p>
          </div>
          <img src={Home2} alt="online"/>
        </div>

        <div className="slide-3">
          <div className="info3">
            <span>Learning Management System</span>
            <span>Manage Study Plan<br/>& Learning material</span>
            <img src={Home3} alt="study-plan"/>
          </div>
          <div className="cards3">
            <div className="info-card3">
              <img src={Element} alt="ele"/>
              <span>Subjects</span>
              <div className="info-points">
                Create Student plan for individual subjects. Organise subject content on multiple levels
              </div>
            </div>
            <div className="info-card3">
              <img src={Element} alt="ele"/>
              <span>Chapters</span>
              <div className="info-points">
                Subjects content created in an organised manner on every unit, chapter, topics and subtopics level.
              </div>
            </div>
            <div className="info-card3">
              <img src={Element} alt="ele"/>
              <span>Notes</span>
              <div className="info-points">
                Create and assign notes content to students. Content created can be assigned automatically to entire class.
              </div>
            </div>
            <div className="info-card3">
              <img src={Element} alt="ele"/>
              <span>Videos</span>
              <div className="info-points">
                Create and assign notes content to students. Content created can be assigned automatically to entire class.
              </div>
            </div>
          </div>
        </div>
        <div className="slide-4">
          <div className="info4">
            <div className="info-data4">
              <span>Assessment Engine</span>
              <span>Conduct Tests & Manage<br/>Question Bank</span>
            </div>
            <img src={Home4} alt="assessment"/>
          </div>
          <div className="cards4">
            <div className="info-card4">
              <img src={Element} alt="ele"/>
              <span>Question Bank</span>
              <div className="info-points">
                Create questions of multiple types and reuse in multiple assessments.
              </div>
            </div>
            <div className="info-card4">
              <img src={Element} alt="ele"/>
              <span>Practice</span>
              <div className="info-points">
                Create practice tests and configure settings as per individual class pace. Analyse class and individual student performance.
              </div>
            </div>
            <div className="info-card4">
              <img src={Element} alt="ele"/>
              <span>Schedule Tests</span>
              <div className="info-points">
                Schedule real time mock tests and review scorecard performance for overall class and individual student.
              </div>
            </div>
          </div>
        </div>
        <div className="footer">
          <div className="contact">
            <img src={Dots} alt="contact-us"/>
            <div>
              Let us know for more customizations. We are happy<br/>to hear from you.
               Contact for free demonstration.
              <span>We provide customised solutions for your<br/>personalised school setup.</span>
            </div>
            <button>
              CONTACT US
            </button>
          </div>
          <div className="footer-1">
            <div className="footer-apps">
              <a href="#" target="__blank">
                <img src={PlayStore} alt="play"/>
              </a>
              <a href="#" target="__blank">
                <img src={AppStore} alt="app"/>
              </a>
            </div>
            Powered by
            <img src={Noesis} alt="noesis"/>
          </div>
          <div className="footer-2">
            Copyright © 2020 All rights reserved
          </div>
          <div className="footer-3">
            <Link to={`/company`}>
              Company
            </Link>
            <Link to={`/contact-us`}>
              Contact Us
            </Link>
            <Link to={`/privacy`}>
              Privacy Policy
            </Link>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = (dispatch) => {
  const { centerCreate } = actions;
  return {
    centerCreate: (name, email, mobile, brand_name, center_type) => dispatch(centerCreate(name, email, mobile, brand_name, center_type))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);